import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`👾 Algorithms`}</h1>
    <ul>
      <li parentName="ul">{`First, read about the `}<a parentName="li" {...{
          "href": "/polis-opinion-matrix",
          "title": "polis opinion matrix"
        }}>{`polis opinion matrix`}</a>{` to understand the format of data `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` gathers from `}<a parentName="li" {...{
          "href": "/participants",
          "title": "participants"
        }}>{`participants`}</a>{` and provides in its `}<a parentName="li" {...{
          "href": "/export",
          "title": "export"
        }}>{`export`}</a>{``}</li>
      <li parentName="ul">{`Dimensionality reduction `}<a parentName="li" {...{
          "href": "/Algorithms",
          "title": "👾 Algorithms"
        }}>{`👾 Algorithms`}</a>{` `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` uses:`}
        <ul parentName="li">
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/PCA",
              "title": "PCA"
            }}>{`PCA`}</a>{``}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/lmcinnes/umap"
            }}>{`UMAP`}</a>
            <ul parentName="li">
              <li parentName="ul">{`UMAP explained: `}<a parentName="li" {...{
                  "href": "https://www.youtube.com/watch?v=G9s3cE8TNZo&ab_channel=MachineLearningDojowithTimScarfe"
                }}>{`https://www.youtube.com/watch?v=G9s3cE8TNZo&ab_channel=MachineLearningDojowithTimScarfe`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Explanations of dimensionality reduction `}<a parentName="li" {...{
          "href": "/Algorithms",
          "title": "👾 Algorithms"
        }}>{`👾 Algorithms`}</a>{`:`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=wvsE8jm1GzE"
            }}>{`What is a high dimensional space?`}</a>{`  `}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://idyll.pub/post/dimensionality-reduction-293e465c2a3443e8941b016d/"
            }}>{`The Beginner's Guide to Dimensionality Reduction`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://setosa.io/ev/principal-component-analysis/"
            }}>{`Principle Components Analysis Explained Visually`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://joshualoftus.com/post/2020-11-23-least-squares-as-springs/least-squares-as-springs/"
            }}>{`Physics Intuition for Regression: PCA as Springs`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Clustering`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://scikit-learn.org/stable/modules/generated/sklearn.cluster.KMeans.html"
            }}>{`K-Means`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/vtraag/leidenalg"
            }}>{`Leiden graph based community detection`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.scipy.org/doc/scipy/reference/generated/scipy.cluster.hierarchy.linkage.html"
            }}>{`Hierarchical clustering`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Explanations of clustering`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://home.deib.polimi.it/matteucc/Clustering/tutorial_html/"
            }}>{`Clustering: An Introduction`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://en.wikipedia.org/wiki/Cluster_analysis"
            }}>{`Cluster Analysis (Wikipedia)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.slideshare.net/ssakpi/graph-based-clustering"
            }}>{`Graph Based Clustering`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.cwts.nl/blog?article=n-r2u2a4"
            }}>{`Explanation of Leiden`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`General summary statistics`}
        <ul parentName="li">
          <li parentName="ul">{`See: `}<a parentName="li" {...{
              "href": "/Analysis",
              "title": "🔬 Analysis"
            }}>{`🔬 Analysis`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ul">{`"Does `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` use natural language processing (NLP)?"`}
        <ul parentName="li">
          <li parentName="ul">{`"No. The machine learning `}<a parentName="li" {...{
              "href": "/Algorithms",
              "title": "👾 Algorithms"
            }}>{`👾 Algorithms`}</a>{` run are solely run on the `}<a parentName="li" {...{
              "href": "/polis-opinion-matrix",
              "title": "polis opinion matrix"
            }}>{`polis opinion matrix`}</a>{` of agrees, disagrees and passes by participants on comments. Thus, `}<a parentName="li" {...{
              "href": "/Polis",
              "title": "Polis"
            }}>{`Polis`}</a>{` is language agnostic."`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      